import {
  Box,
  DialogActions,
  IconButton
} from '@mui/material';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { Close } from '@mui/icons-material';
import { api } from 'src/services/api';
import useHeaders from 'src/services/headers';

function DeleteItem(props) {
  const { onClose, selectedValue, open, data } = props;
  const headers = useHeaders();
  
  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  const handleListItemClickDelete = (value) => {
    onClose(value);

      api.delete(`/responsavel/${data.id}`, {
        headers: headers,
      })
      .then((response) => {
          window.location.reload();
      })
      .catch((err) => {
        console.error("error" + err);
      });
    
  };


  return (
    <Dialog onClose={handleClose} open={open} maxWidth="sm" fullWidth>
      <DialogTitle>Deseja apagar o item selecionado?</DialogTitle>
      <Box position="absolute" top={0} right={0}>
        <IconButton>
          <Close />
        </IconButton>
      </Box>
      <DialogActions>
        <Button variant="outlined" sx={{ margin: 1 }} onClick={() => handleListItemClick(false)}>
          Cancel
        </Button>
        <Button color="secondary" variant="contained" onClick={() => handleListItemClickDelete(false)}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteItem;