import {
  Autocomplete,
  createFilterOptions,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  MenuItem,
  TextField,
  Typography
} from '@mui/material';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { useEffect, useReducer, useState } from 'react';
import { api } from 'src/services/api';
import { DateTimePicker } from '@mui/lab';
import ModalConfirmation from './ModalConfirmation';
import useHeaders from 'src/services/headers';

function ModalClient(props) {
  const [openModalConfirmation, setOpenModalConfirmation] = useState(false);
  const [error, setError] = useState(false);
  const { type, onClose, selectedValue, open, data } = props;
  const typeForm = type == 'update' ? true : false
  const [formInput, setFormInput] = useReducer(
    (state, newState) => ({ ...state, ...newState }),{
    }
  );
  const headers = useHeaders();

  const handleClose = () => {
    if (!error) {
      onClose(selectedValue);
    }
  };

  const handleCloseCancel = () => {
    onClose(selectedValue);
  };

  const handleCloseModalConfirmation = () => {
    setOpenModalConfirmation(false);
  };

  const resetForm = () => {
    setFormInput({
      descricao: '',
    })
  }

  const handleInput = event => {
    const name = event.target.name;
    const newValue = event.target.value;
    setFormInput({ [name]: newValue });
  };

  const handleSubmit = event => {
    event.preventDefault();

    let formData = { formInput };

    if (typeForm) {
      api.patch(`/responsavel/${data.id}/`, formData.formInput, 
      {
        headers: headers,
      })
      .then((response) => {
        resetForm();
        setOpenModalConfirmation(true);})
      .catch((error) => {
        setError(true);
        alert(error.message)
      });
    } else {
      api.post(`/responsavel/`, formData.formInput, 
      {
        headers: headers,
      })
      .then((response) => {
        resetForm();
        setOpenModalConfirmation(true);})
      .catch((error) => {
        setError(true);
        alert(error.message)
      });
    }

  };

  return (
    <>
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="edit-apartment"
    >
    <DialogTitle>
      {" "}
      <Typography variant="h4">{typeForm ? "Atualizar Responsável" : "Criar Responsável"}</Typography>
    </DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Grid container spacing={2}>

            <Grid item xs={12}>
              <TextField
                autoFocus
                fullWidth
                id="descricao"
                name="descricao"
                label="Descrição"
                type="text"
                defaultValue={typeForm ? data?.descricao : ''}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={handleInput}
              />
            </Grid>
          
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCancel} color="secondary">
            Cancelar
          </Button>
          <Button onClick={handleClose} type="submit" color="primary">
          {typeForm ? "Atualizar" : "Cadastrar"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
    <ModalConfirmation 
      typeForm={typeForm}
      open={openModalConfirmation}
      onClose={handleCloseModalConfirmation}
    />
    </>
  );
}

export default ModalClient;
