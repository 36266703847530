import {
  Box,
  DialogActions,
  IconButton
} from '@mui/material';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { Close } from '@mui/icons-material';
import { api } from 'src/services/api';


function ModalConfirmation(props) {
  const { onClose, selectedValue, open, typeForm } = props;

  const closeModal = () => {
    onClose(false)
    window.location.reload();
  }

  const handleClose = () => {
    onClose(selectedValue);

  };


  return (
    <Dialog onClose={handleClose} open={open} maxWidth="sm" fullWidth>
      <DialogTitle>Item {typeForm ? 'atualizado' : 'salvo'} com sucesso!</DialogTitle>
      <Box position="absolute" top={0} right={0}>
        <IconButton>
          <Close />
        </IconButton>
      </Box>
      <DialogActions>
        <Button variant="outlined" sx={{ margin: 1 }} onClick={() => closeModal()}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ModalConfirmation;