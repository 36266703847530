import { FC, ChangeEvent, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Tooltip,
  Divider,
  Box,
  Card,
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Typography,
  useTheme,
  CardHeader,
  Chip
} from '@mui/material';

import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import BulkActions from './BulkActions';
import DeleteItem from './DeleteItem';
import ModalTree from './ModalResponsible';
import moment from 'moment';
import ModalDetail from './ModalDetail';
import { api } from 'src/services/api';
import { ClientData } from 'src/models/client';
import { ResponsibleData } from 'src/models/responsible';
import useHeaders from 'src/services/headers';

interface ResponsibleTableProps {
  className?: string;
  responsibleData: ResponsibleData[];
  data: any;
}

const ResponsibleTable: FC<ResponsibleTableProps> = (props) => {
  const [clientData, setClientData] = useState(props.responsibleData);
  const [data, setData] = useState(props.data);

  useEffect(() => {
    setClientData(props.responsibleData);
  }, [props.responsibleData]);

  useEffect(() => {
    setData(props.data);
  }, [props.data]);


  const [selectedPlantData, setSelectedPlantData] = useState<string[]>(
    []
  );
  const selectedBulkActions = selectedPlantData.length > 0;
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);

  const [open, setOpen] = useState(false);
  const [openUpdated, setOpenUpdated] = useState(false);

  const [selectedClient, setSelectedClient] = useState({});

  const headers = useHeaders();
  
  // Delete
  const handleClickOpen = (plantData) => {
    setSelectedClient(plantData)
    setOpen(true);
  };
  const handleClose = (value) => {
    setOpen(false);
  };

  // Update
  const handleClickOpenUpdated = (plantData) => {
    setSelectedClient(plantData)
    setOpenUpdated(true);
  };
  const handleCloseUpdated = (value) => {
    setOpenUpdated(false);
  };

  moment.locale('pt-br');

  const handleSelectAllPlantData = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    setSelectedPlantData(
      event.target.checked
        ? clientData.map((transporterData) => transporterData.id)
        : []
    );
  };

  const handleSelectOneTree = (
    event: ChangeEvent<HTMLInputElement>,
    treeId: string
  ): void => {
    if (!selectedPlantData.includes(treeId)) {
      setSelectedPlantData((prevSelected) => [
        ...prevSelected,
        treeId
      ]);
    } else {
      setSelectedPlantData((prevSelected) =>
        prevSelected.filter((id) => id !== treeId)
      );
    }
  };

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);

    api
      .get("/cliente/", { params: { page: newPage+1, page_size: limit},
        headers: headers })
      .then((response) => {
        setClientData(response.data.results)
      })
      .catch((err) => {
        console.error("error" + err);
      });
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const page_size = parseInt(event.target.value);
    setLimit(page_size);

    api
      .get("/cliente/", { params: { page: page+1, page_size: page_size},
        headers: headers })
      .then((response) => {
        setClientData(response.data.results)
      })
      .catch((err) => {
        console.error("error" + err);
      });
  };

  const selectedSomePlantData =
    selectedPlantData.length > 0 &&
    selectedPlantData.length < clientData.length;
  const selectedAllPlantData =
    selectedPlantData.length === clientData.length;
  const theme = useTheme();

  return (
    <Card>
      {selectedBulkActions && (
        <Box flex={1} p={2}>
          <BulkActions />
        </Box>
      )}
      {!selectedBulkActions && (
        <CardHeader
        />
      )}
      <Divider />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                />
              </TableCell>
              <TableCell>ID</TableCell>
              <TableCell>Descrição</TableCell>
              <TableCell align="center">Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {clientData.map((value) => {
              const isPlantDataSelected = selectedPlantData.includes(
                value.id
              );
              return (
                <TableRow
                  hover
                  key={value.id}
                  selected={isPlantDataSelected}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                    />
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {value?.id}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      align="left"
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {value?.descricao}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip title="Atualizar" arrow>
                      <IconButton
                        sx={{
                          '&:hover': {
                            background: theme.colors.info.lighter
                          },
                          color: theme.palette.info.main
                        }}
                        color="inherit"
                        size="small"
                      >
                        <EditTwoToneIcon fontSize="small" onClick={() => handleClickOpenUpdated(value)}/>
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Deletar" arrow>
                      <IconButton
                        sx={{
                          '&:hover': { background: theme.colors.error.lighter },
                          color: theme.palette.error.main
                        }}
                        color="inherit"
                        size="small"
                      >
                        <DeleteTwoToneIcon fontSize="small" onClick={() => handleClickOpen(value)}/>
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box p={2}>
        <TablePagination
          component="div"
          count={data?.count}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[10, 30, 50, 100]}
        />
      </Box>
      <DeleteItem
        open={open}
        data={selectedClient}
        onClose={handleClose}
      />

      <ModalTree
        type='update'
        open={openUpdated}
        data={selectedClient}
        onClose={handleCloseUpdated}
      />
    </Card>
    
  );
};


ResponsibleTable.propTypes = {
  responsibleData: PropTypes.array.isRequired
};

ResponsibleTable.defaultProps = {
  responsibleData: []
};

export default ResponsibleTable;
