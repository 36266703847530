import { Suspense, lazy, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject, useNavigate } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';
import Responsible from './content/applications/Responsible';
import { useAuth } from './hooks/auth';
import { api } from './services/api';
import useHeaders from './services/headers';
import { USER_TYPE_ADMIN, USER_TYPE_OPERADORES } from './content/applications/constants';

interface UserType {
  tipo_perfil: string;
  email: string
}

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages

const Login = Loader(lazy(() => import('src/content/login')));

// Applications

const Transactions = Loader(
  lazy(() => import('src/content/applications/Transactions'))
);

// GLOG

const Trees = Loader(
  lazy(() => import('src/content/applications/Trees'))
);
const Plant = Loader(
  lazy(() => import('src/content/applications/Plant'))
);
const Transporter = Loader(
  lazy(() => import('src/content/applications/Transporter'))
);
const OperationType = Loader(
  lazy(() => import('src/content/applications/OperationType'))
);
const Proccess = Loader(
  lazy(() => import('src/content/applications/Proccess'))
);
const Occurrence = Loader(
  lazy(() => import('src/content/applications/Occurrence'))
);
const ProccessOccurrence = Loader(
  lazy(() => import('src/content/applications/ProccessOccurrence'))
);
const ProcessOccurrenceInteraction = Loader(
  lazy(() => import('src/content/applications/ProcessOccurrenceInteraction'))
);
const DischargeBase = Loader(
  lazy(() => import('src/content/applications/DischargeBase'))
);
const Contact = Loader(
  lazy(() => import('src/content/applications/Contact'))
);
const Response = Loader(
  lazy(() => import('src/content/applications/Response'))
);
const CreateTree = Loader(
  lazy(() => import('src/content/applications/CreateTree'))
);
const UserPermissions = Loader(
  lazy(() => import('src/content/applications/UserPermissions'))
);
const Client = Loader(
  lazy(() => import('src/content/applications/Client'))
);
const CreateQuestions = Loader(
  lazy(() => import('src/content/applications/CreateQuestions'))
);
const CreateInteration = Loader(
  lazy(() => import('src/content/applications/CreateInteration'))
);

// Status
const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/content/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/content/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/content/pages/Status/Maintenance'))
);
const NoAccess = Loader(
  lazy(() => import('src/content/applications/NoAccess'))
);

const UserTypeChecker = ({ accountInfo, allowedTypes, children }) => {
  const [isAllowed, setIsAllowed] = useState(false);
  const headers = useHeaders();

  useEffect(() => {
    const fetchUserType = async () => {
      try {
        const response = await api.get("/tipo/usuario/", {
          headers: headers,
        });

        const userType = response.data.results?.tipo_perfil;
        setIsAllowed(allowedTypes.includes(userType));
      } catch (err) {
        console.error(err);
      }
    };

    if (accountInfo?.isAuthenticated) {
      fetchUserType();
    }
  }, [accountInfo, headers, allowedTypes]);

  if (isAllowed) {
    return <>{children(isAllowed)}</>;
  }

  return <NoAccess />;
};

const PrivateRouteAdmin = ({ element }: RouteObject) => {
  const { accountInfo } = useAuth();

  return (
    <UserTypeChecker accountInfo={accountInfo} allowedTypes={[USER_TYPE_ADMIN]}>
      {(isAllowed) => (isAllowed ? <>{element}</> : <NoAccess />)}
    </UserTypeChecker>
  );
};

const PrivateRouteAll = ({ element }: RouteObject) => {
  const { accountInfo } = useAuth();

  if (!accountInfo?.isAuthenticated) {
    return <Login />;
  } else {
    return (
      <UserTypeChecker
        accountInfo={accountInfo}
        allowedTypes={[USER_TYPE_ADMIN, USER_TYPE_OPERADORES]}
      >
        {(isAllowed) => (isAllowed ? <>{element}</> : <NoAccess />)}
      </UserTypeChecker>
    );
  }
};

const PrivateRoute = ({ element }: RouteObject) => {
  const { accountInfo } = useAuth();

  if (!accountInfo?.isAuthenticated) {
    return <Login />;
  } else {
    return <>{element}</>;
  }
};

const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Login />
      },
      {
        path: 'status',
        children: [
          {
            path: '',
            element: <Navigate to="404" replace />
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />
          }
        ]
      },
      {
        path: '*',
        element: <PrivateRoute element={<Status404 />} />
      }
    ]
  },
  {
    path: 'management',
    element: <PrivateRoute element={<SidebarLayout />} />,
    children: [
      {
        path: '',
        element: <Navigate to="trees" replace />
      },
      {
        path: 'transactions',
        element: <PrivateRouteAdmin element={<Transactions />} />,
      },
      {
        path: 'ocorrencias',
        element: <PrivateRouteAll element={<Trees />} />,
      },
      {
        path: 'usina',
        element: <PrivateRouteAdmin element={<Plant />} />,
      },
      {
        path: 'clientes',
        element: <PrivateRouteAdmin element={<Client />} />,
      },
      {
        path: 'responsaveis',
        element: <PrivateRouteAdmin element={<Responsible />} />,
      },
      {
        path: 'transportador',
        element: <PrivateRouteAdmin element={<Transporter />} />,
      },
      {
        path: 'fluxo-logistico',
        element: <PrivateRouteAdmin element={<OperationType />} />,
      },
      {
        path: 'processo',
        element: <PrivateRouteAdmin element={<Proccess />} />,
      },
      {
        path: 'tipos-ocorrencia',
        element: <PrivateRouteAdmin element={<Occurrence />} />,
      },
      {
        path: 'processo-ocorrencia',
        element: <PrivateRouteAdmin element={<ProccessOccurrence />} />,
      },
      {
        path: 'pergunta-regras',
        element: <PrivateRouteAdmin element={<ProcessOccurrenceInteraction />} />,
      },
      {
        path: 'criar-pergunta-regras',
        element: <PrivateRouteAdmin element={<CreateInteration />} />,
      },
      {
        path: 'criar-pergunta-regras/:id',
        element: <PrivateRouteAdmin element={<CreateInteration />} />,
      },
      {
        path: 'base-descarga',
        element: <PrivateRouteAdmin element={<DischargeBase />} />,
      },
      {
        path: 'contatos',
        element: <PrivateRouteAdmin element={<Contact />} />,
      },
      {
        path: 'planos-acao',
        element: <PrivateRouteAdmin element={<Response />} />,
      },
      {
        path: 'criar-ocorrencia',
        element: <PrivateRoute element={<CreateTree />} />,
      },
      {
        path: 'criar-ocorrencia/:id',
        element: <PrivateRouteAdmin element={<CreateTree />} />,
      },
      {
        path: 'pergunta',
        element: <PrivateRouteAdmin element={<CreateQuestions />} />,
      },
      {
        path: 'users-permissions',
        element: <PrivateRouteAdmin element={<UserPermissions />} />,
      },
    ]
  },
];

export { PrivateRoute, routes };