import { useContext, useEffect, useState } from 'react';

import {
  ListSubheader,
  alpha,
  Box,
  List,
  styled,
  Button,
  ListItem
} from '@mui/material';
import { NavLink as RouterLink } from 'react-router-dom';
import { SidebarContext } from 'src/contexts/SidebarContext';

import TableChartTwoToneIcon from '@mui/icons-material/TableChartTwoTone';

import { People, AccountTree, Engineering, FormatListNumbered, Loop, Quiz, Summarize, TimeToLeave, Storage, FolderOpen, PermContactCalendar, Reply, Visibility, Groups, SupervisorAccount, LiveHelp } from '@mui/icons-material';
import { api } from 'src/services/api';
import { useAuth } from 'src/hooks/auth';
import useHeaders from 'src/services/headers';
import { USER_TYPE_ADMIN, USER_TYPE_OPERADORES } from 'src/content/applications/constants';

const MenuWrapper = styled(Box)(
  ({ theme }) => `
  .MuiList-root {
    padding: ${theme.spacing(1)};

    & > .MuiList-root {
      padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
    }
  }

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(12)};
      color: ${theme.colors.alpha.trueWhite[50]};
      padding: ${theme.spacing(0, 2.5)};
      line-height: 1.4;
    }
`
);

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {

      .MuiListItem-root {
        padding: 1px 0;

        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(3.2)};

          .MuiBadge-standard {
            background: ${theme.colors.primary.main};
            font-size: ${theme.typography.pxToRem(10)};
            font-weight: bold;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }
    
        .MuiButton-root {
          display: flex;
          color: ${theme.colors.alpha.trueWhite[70]};
          background-color: transparent;
          width: 100%;
          justify-content: flex-start;
          padding: ${theme.spacing(1.2, 3)};

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(['color'])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            color: ${theme.colors.alpha.trueWhite[30]};
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1)};
          }
          
          .MuiButton-endIcon {
            color: ${theme.colors.alpha.trueWhite[50]};
            margin-left: auto;
            opacity: .8;
            font-size: ${theme.typography.pxToRem(20)};
          }

          &.active,
          &:hover {
            background-color: ${alpha(theme.colors.alpha.trueWhite[100], 0.06)};
            color: ${theme.colors.alpha.trueWhite[100]};

            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${theme.colors.alpha.trueWhite[100]};
            }
          }
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(7)};
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px 0;

            .MuiButton-root {
              padding: ${theme.spacing(0.8, 3)};

              .MuiBadge-root {
                right: ${theme.spacing(3.2)};
              }

              &:before {
                content: ' ';
                background: ${theme.colors.alpha.trueWhite[100]};
                opacity: 0;
                transition: ${theme.transitions.create([
                  'transform',
                  'opacity'
                ])};
                width: 6px;
                height: 6px;
                transform: scale(0);
                transform-origin: center;
                border-radius: 20px;
                margin-right: ${theme.spacing(1.8)};
              }

              &.active,
              &:hover {

                &:before {
                  transform: scale(1);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
`
);

function SidebarMenu() {  
  interface UserType {
    tipo_perfil: string;
    email: string;
  }

  const { closeSidebar } = useContext(SidebarContext);
  const { accountInfo } = useAuth();
  const [isAdmin, setIsAdmin] = useState(false);
  const [isOperator, setIsOperator] = useState(false);
  const headers = useHeaders();

  useEffect(() => {
    if (accountInfo?.isAuthenticated) {
      getUserType()
        .then((userType) => {
          setIsAdmin(userType?.tipo_perfil === USER_TYPE_ADMIN);
          setIsOperator(userType?.tipo_perfil === USER_TYPE_OPERADORES);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [accountInfo]);

  const getUserType = async (): Promise<UserType> => {
    try {
      const response = await api.get("/tipo/usuario/", {
        headers: headers,
      });
      return response.data.results;
    } catch (err) {
      console.error("error", err);
      throw err;
    }
  };

  return (
    <>
      <MenuWrapper>
        {
          isAdmin || isOperator && (
            <div>
              <List
                component="div"
                subheader={
                  <ListSubheader component="div" disableSticky>
                    Ocorrências
                  </ListSubheader>
                }
              >
                <SubMenuWrapper>
                  <List component="div">
                    <ListItem component="div">
                      <Button
                        disableRipple
                        component={RouterLink}
                        onClick={closeSidebar}
                        to="/management/ocorrencias"
                        startIcon={<AccountTree />}
                      >
                        Ocorrências
                      </Button>
                    </ListItem>
                  </List>
                </SubMenuWrapper>
              </List>
            </div>
          )
        }
        {
          isAdmin && (
            <div>
              <List
                component="div"
                subheader={
                  <ListSubheader component="div" disableSticky>
                    Motor de Regras
                  </ListSubheader>
                }
              >
                <SubMenuWrapper>
                  <List component="div">
                    <ListItem component="div">
                      <Button
                        disableRipple
                        component={RouterLink}
                        onClick={closeSidebar}
                        to="/management/processo-ocorrencia"
                        startIcon={<FormatListNumbered />}
                      >
                        Processo Ocorrência
                      </Button>
                    </ListItem>
                    <ListItem component="div">
                      <Button
                        disableRipple
                        component={RouterLink}
                        onClick={closeSidebar}
                        to="/management/pergunta-regras"
                        startIcon={<Quiz />}
                      >
                        Perguntas por Ocorrência
                      </Button>
                    </ListItem>

                  </List>
                </SubMenuWrapper>
              </List>

              <List
                component="div"
                subheader={
                  <ListSubheader component="div" disableSticky>
                    Gerenciamento - Cadastros
                  </ListSubheader>
                }
              >
                <SubMenuWrapper>
                  <List component="div">
                    <ListItem component="div">
                      <Button
                        disableRipple
                        component={RouterLink}
                        onClick={closeSidebar}
                        to="/management/users-permissions"
                        startIcon={<People />}
                      >
                        Permissão de Usuários
                      </Button>
                    </ListItem>

                    <ListItem component="div">
                      <Button
                        disableRipple
                        component={RouterLink}
                        onClick={closeSidebar}
                        to="/management/base-descarga"
                        startIcon={<Storage />}
                      >
                        Base Descarga
                      </Button>
                    </ListItem>

                    <ListItem component="div">
                      <Button
                        disableRipple
                        component={RouterLink}
                        onClick={closeSidebar}
                        to="/management/clientes"
                        startIcon={<Groups />}
                      >
                        Cliente
                      </Button>
                    </ListItem>

                    <ListItem component="div">
                      <Button
                        disableRipple
                        component={RouterLink}
                        onClick={closeSidebar}
                        to="/management/contatos"
                        startIcon={<PermContactCalendar />}
                      >
                        Contato
                      </Button>
                    </ListItem>

                    <ListItem component="div">
                      <Button
                        disableRipple
                        component={RouterLink}
                        onClick={closeSidebar}
                        to="/management/fluxo-logistico"
                        startIcon={<FolderOpen />}
                      >
                        Fluxo logístico
                      </Button>
                    </ListItem>

                    <ListItem component="div">
                      <Button
                        disableRipple
                        component={RouterLink}
                        onClick={closeSidebar}
                        to="/management/pergunta"
                        startIcon={<LiveHelp />}
                      >
                        Pergunta
                      </Button>
                    </ListItem>

                    <ListItem component="div">
                      <Button
                        disableRipple
                        component={RouterLink}
                        onClick={closeSidebar}
                        to="/management/processo"
                        startIcon={<Summarize />}
                      >
                        Processo
                      </Button>
                    </ListItem>

                    <ListItem component="div">
                      <Button
                        disableRipple
                        component={RouterLink}
                        onClick={closeSidebar}
                        to="/management/responsaveis"
                        startIcon={<SupervisorAccount />}
                      >
                        Responsáveis
                      </Button>
                    </ListItem>

                    <ListItem component="div">
                      <Button
                        disableRipple
                        component={RouterLink}
                        onClick={closeSidebar}
                        to="/management/tipos-ocorrencia"
                        startIcon={<Loop />}
                      >
                        Tipos de ocorrência
                      </Button>
                    </ListItem>

                    <ListItem component="div">
                      <Button
                        disableRipple
                        component={RouterLink}
                        onClick={closeSidebar}
                        to="/management/transportador"
                        startIcon={<TimeToLeave />}
                      >
                        Transportador
                      </Button>
                    </ListItem>

                    <ListItem component="div">
                      <Button
                        disableRipple
                        component={RouterLink}
                        onClick={closeSidebar}
                        to="/management/usina"
                        startIcon={<Engineering />}
                      >
                        Usina
                      </Button>
                    </ListItem>

                  </List>
                </SubMenuWrapper>
              </List>
            </div>
          )
        }

      </MenuWrapper>
    </>
  );
}

export default SidebarMenu;
