import { Card } from '@mui/material';
import { useEffect, useState } from 'react';
import { ClientData } from 'src/models/client';
import { ResponsibleData } from 'src/models/responsible';
import { api } from 'src/services/api';
import ResponsibleTable from './ResponsibleTable';
import useHeaders from 'src/services/headers';

function ResponsibleView() {
  const [responsible, setResponsible] = useState<ResponsibleData[]>();
  const [data, setData] = useState<ResponsibleData[]>();
  const headers = useHeaders();
  
  useEffect(() => {
    api
      .get("/responsavel/", { params: { page: 1, page_size: 10 },
        headers: headers })
      .then((response) => {
        setResponsible(response.data.results)
        setData(response.data)
      })
      .catch((err) => {
        console.error("error" + err);
      });
  }, []);
  
  const responsibleData: ResponsibleData[] = responsible

  return (
    <Card>
      <ResponsibleTable responsibleData={responsibleData} data={data} />
    </Card>
  );
}

export default ResponsibleView;
